<template>
  <LayoutSpSa>
    <template #azioni>
      <ActionsSpSa id="azioni-dati-utente">
        <a @click="toggleEdit">
          <font-awesome-icon icon="edit" v-if="!editing" />
          <font-awesome-icon icon="eye" v-else />
        </a>
      </ActionsSpSa>
    </template>
    <template>
      <FormUtenteSpSa
        :parent="NAME"
        :edit="editing"
        :key="'userEd_' + editing"
      />
    </template>
  </LayoutSpSa>
</template>
<script>
const NAME = "DatiUtenteSpSa";

import LayoutSpSa from "@/components/_layout/LayoutSpSa";
import ActionsSpSa from "@/components/_common/ActionsSpSa";
import FormUtenteSpSa from "@/components/_common/FormUtenteSpSa";

export default {
  name: NAME,
  data() {
    return {
      NAME: NAME,
      editing: false,
    };
  },
  computed: {
    editIcon() {
      return this.editing ? "👁" : "✎";
    },
  },
  components: {
    LayoutSpSa,
    ActionsSpSa,
    FormUtenteSpSa,
  },
  methods: {
    toggleEdit(e) {
      this.editing = !this.editing;
      if (this.editing) {
        // per permettere che il focus venga assegnato al primo input
        e.preventDefault();
      }
    },
  },
};
</script>

