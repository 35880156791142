<template>
  <section class="actions">
    <slot />
  </section>
</template>

<script>
const NAME = "ActionsSpSa";
export default {
  name: NAME,
};
</script>

<style src="@/styles/custom/components/_common/_actions.scss" lang="scss"></style>